import { PROFILE_PRACTITIONER_TYPES } from "../utils/profilesUtil";

export const state = () => ({
  isEditMode: false,
  profileCareTeam: [],
  siteCareTeam: [],
  siteCareTeamByRole: {},
  careTeamUpdateArr: [],
  providerUpdateMap: {
    [PROFILE_PRACTITIONER_TYPES.REFERRAL]: [],
    [PROFILE_PRACTITIONER_TYPES.PRIMARY]: [],
  },
  practitionerUpdateKey: 1,
  practitionerAssignmentResults: { success: [], rejected: [] },
  loading: true,
  lastUpdatedDate: null,
})
export const getters = {
  profileCareTeamByRole: (state, getters, rootState) => rootState.portal.allSiteUserRoles.map(role => {
    const member = state.profileCareTeam.find(member => member.roles.some(r => r.code === role.code));
    return member ? { userId: member.id, role: role.code } : null;
  }).filter(item => item !== null),
  lastUpdatedDateFromNow: (state) => state.lastUpdatedDate ? moment(state.lastUpdatedDate).fromNow() : null,
  getCareTeamMemberHasRole: (state) => (role) => {
    return state.profileCareTeam.find((member) => member.rolesForPatient.includes(role.code));
  },
  careTeamOptions: (state) => (roleCode) => {
    return state.siteCareTeam.filter(member => member.roles.some(r => r.code === roleCode));
  },
  draftReferringProviders: (state) => state.providerUpdateMap[PROFILE_PRACTITIONER_TYPES.REFERRAL],
  draftPrimaryProviders: (state) => state.providerUpdateMap[PROFILE_PRACTITIONER_TYPES.PRIMARY],
  sitePractitioners: (state, getters, rootState) => rootState.portal.detailedSite?.practitioners || [],
  profilePractitioners: (state, getters, rootState, rootGetters) => rootGetters['profile/profilePractitioners'],
  practitionerOptions: (_, getters) => {
    const options = getters.profilePractitioners.map(pp => pp.practitioner);
    getters.sitePractitioners.forEach(sp => {
      if (!options.find(pract => pract.npi === sp.npi)) {
        options.push(sp);
      }
    })
    return options;
  },
  getProviderFromNpi: (_, getters) => (npi) => getters.practitionerOptions.find(option => option.npi === npi),
  referringProviders: (_, getters) => getters.profilePractitioners.filter(p => {
    return p.type === PROFILE_PRACTITIONER_TYPES.REFERRAL || p.type === PROFILE_PRACTITIONER_TYPES.REFERRAL_AND_PRIMARY;
  }),
  primaryProviders: (_, getters) => getters.profilePractitioners.filter(p => {
    return p.type === PROFILE_PRACTITIONER_TYPES.PRIMARY || p.type === PROFILE_PRACTITIONER_TYPES.REFERRAL_AND_PRIMARY;
  }),
  sortedReferringProviders: (_, getters) => getters.referringProviders.sort((a, b) => a.practitioner.name?.lastName > b.practitioner.name?.lastName ? 1 : -1),
  sortedPrimaryProviders: (_, getters) => getters.primaryProviders.sort((a, b) => a.practitioner.name?.lastName > b.practitioner.name?.lastName ? 1 : -1),
  newPractitionerAssignments: (_, getters) => {
    const assignments = {
      [PROFILE_PRACTITIONER_TYPES.REFERRAL]: new Set(),
      [PROFILE_PRACTITIONER_TYPES.PRIMARY]: new Set(),
      [PROFILE_PRACTITIONER_TYPES.REFERRAL_AND_PRIMARY]: new Set(),
    }
    const referringProviderNpis = new Set(getters.referringProviders.map(rp => rp.practitioner.npi));
    const primaryProviderNpis = new Set(getters.primaryProviders.map(pp => pp.practitioner.npi));
    getters.draftReferringProviders.forEach(drp => {
      if (!referringProviderNpis.has(drp.npi)) {
        if (primaryProviderNpis.has(drp.npi) || getters.draftPrimaryProviders.find(dpp => dpp.npi === drp.npi)) {
          assignments[PROFILE_PRACTITIONER_TYPES.REFERRAL_AND_PRIMARY].add(drp.npi);
        } else {
          assignments[PROFILE_PRACTITIONER_TYPES.REFERRAL].add(drp.npi);
        }
      }
    })
    getters.draftPrimaryProviders.forEach(dpp => {
      if (!primaryProviderNpis.has(dpp.npi)) {
        if (referringProviderNpis.has(dpp.npi) || getters.draftReferringProviders.find(drp => drp.npi === dpp.npi)) {
          assignments[PROFILE_PRACTITIONER_TYPES.REFERRAL_AND_PRIMARY].add(dpp.npi);
        } else {
          assignments[PROFILE_PRACTITIONER_TYPES.PRIMARY].add(dpp.npi);
        }
      }
    })
    return assignments;
  },
  assignedCareCoordinator: (state) => state.profileCareTeam.find((member) => member.roles.some((r) => r.code === 'care_coordinator')),
}
export const mutations = {
  setEditMode (state, mode) {
    state.isEditMode = mode;
  },
  setProfileCareTeam (state, team) {
    state.profileCareTeam = team;
  },
  setSiteCareTeam (state, team) {
    state.siteCareTeam = team;
  },
  setSiteCareTeamByRole (state, team) {
    state.siteCareTeamByRole = team;
  },
  setCareTeamUpdateArr (state, arr) {
    state.careTeamUpdateArr = arr;
  },
  setLoading (state, loading) {
    state.loading = loading;
  },
  setLastUpdatedDate (state, date) {
    state.lastUpdatedDate = date;
  },
  addToCareTeamUpdateArr (state, { userId, role }) {
    const index = state.careTeamUpdateArr.findIndex(item => item.role === role);
    if (index !== -1) {
      state.careTeamUpdateArr[index] = { userId, role };
    } else {
      state.careTeamUpdateArr.push({ userId, role });
    }
  },
  addToProviderUpdateArr (state, { draftType }) {
    state.providerUpdateMap[draftType].push({
      draftType,
      draftKey: state.practitionerUpdateKey++
    })
  },
  removeFromProviderUpdateArr (state, { draftProvider }) {
    const providerIndex = state.providerUpdateMap[draftProvider.draftType].findIndex(dp => dp.draftKey === draftProvider.draftKey);
    if (providerIndex === -1) {
      console.error('Could not find draft provider to delete: ', draftProvider);
      return;
    }
    state.providerUpdateMap[draftProvider.draftType].splice(providerIndex, 1)
  },
  replaceInProviderUpdateArr (state, { prevProvider, newProvider }) {
    const prevProviderIndex = state.providerUpdateMap[prevProvider.draftType].findIndex(dp => dp.draftKey === prevProvider.draftKey);
    if (prevProviderIndex === -1) {
      console.error('Could not find draft provider to replace');
      return
    }
    newProvider.draftKey = state.practitionerUpdateKey++;
    newProvider.draftType = prevProvider.draftType;
    state.providerUpdateMap[prevProvider.draftType].splice(prevProviderIndex, 1, newProvider);
  },
  resetProviderUpdateArr (state, updateArrKey) {
    state.providerUpdateMap[updateArrKey] = [];
  },
  setPractitionerAssignmentResults (state, newResults) {
    state.practitionerAssignmentResults = newResults;
  },
}
export const actions = {
  async initProfileCareTeam ({ dispatch, commit }, { profileId, siteId }) {
    commit('setLoading', true);
    await Promise.all([dispatch('fetchCareTeamForProfile', profileId), dispatch('fetchCareTeamForSite', siteId)]);
    commit('setLoading', false);
  },
  async fetchCareTeamForProfile ({ commit }, profileId) {
    const response = await this.$apiv2.getCareTeamForProfile(profileId);
    commit('setProfileCareTeam', response);
    return response;
  },
  async fetchCareTeamForSite ({ commit }, siteId) {
    const response = await this.$apiv2.getTeamMembersForSite(siteId);
    commit('setSiteCareTeam', response);
    return response;
  },
  async updateCareTeamForProfile ({ commit, state, getters }, { profileId }) {
    commit('setLoading', true);
    try {
      const payload = getters.profileCareTeamByRole;
      state.careTeamUpdateArr.forEach(item => {
        const index = payload.findIndex(p => p.role === item.role);
        if (index !== -1) {
          if (item.userId !== null) {
            payload[index] = item;
          } else {
            payload.splice(index, 1);
          }
        } else {
          payload.push(item);
        }
      });
      const response = await this.$apiv2.updateTeamMembersForProfile(profileId, payload);
      commit('setProfileCareTeam', response);
    } catch (e) {
      console.log(e)
      throw e
    } finally {
      commit('setLoading', false);
      commit('setEditMode', false);
    }
  },
  addCareTeamToUpdateArr ({ commit }, { userId, role }) {
    commit('addToCareTeamUpdateArr', { userId, role });
  },
  addDraftProvider ({ commit }, draftType) {
    commit('addToProviderUpdateArr', { draftType });
  },
  removeDraftProvider ({ commit }, draftProvider) {
    commit('removeFromProviderUpdateArr', { draftProvider });
  },
  replaceDraftProvider ({ commit }, { prevProvider, newProvider }) {
    commit('replaceInProviderUpdateArr', { prevProvider, newProvider });
  },
  resetDraftProviders ({ commit }) {
    commit('resetProviderUpdateArr', PROFILE_PRACTITIONER_TYPES.REFERRAL);
    commit('resetProviderUpdateArr', PROFILE_PRACTITIONER_TYPES.PRIMARY);
  },
  async unassignProvider ({ dispatch, getters }, { provider, draftType }) {
    if (!provider || !provider.npi) {
      console.warn('Cannot unassign provider: ', provider);
      return;
    }
    let unassignPromise;
    // Handle cases where provider with type "REFERRAL_AND_PRIMARY" is unassigned from just one of those roles.
    if (draftType === PROFILE_PRACTITIONER_TYPES.REFERRAL && getters.primaryProviders.find(pp => pp.practitioner?.npi === provider.npi)) {
      unassignPromise = dispatch('profile/assignPractitioner', { npi: provider.npi, type: PROFILE_PRACTITIONER_TYPES.PRIMARY }, { root: true })
    } else if (draftType === PROFILE_PRACTITIONER_TYPES.PRIMARY && getters.referringProviders.find(rp => rp.practitioner?.npi === provider.npi)) {
      unassignPromise = dispatch('profile/assignPractitioner', { npi: provider.npi, type: PROFILE_PRACTITIONER_TYPES.REFERRAL }, { root: true })
    } else {
      unassignPromise = dispatch('profile/unassignPractitioners', { practitioners: [provider.npi] }, { root: true })
    }
    return Promise.resolve(unassignPromise);
  },
  async assignProviders ({ commit, dispatch, getters }) {
    commit('setPractitionerAssignmentResults', { success: [], rejected: [] });
    const draftAssignments = getters.newPractitionerAssignments
    const draftAssignmentPromises = [];
    const draftAssignmentResults = { success: [], rejected: [] }
    Object.entries(draftAssignments).forEach(([type, npiSet]) => {
      npiSet.forEach(npi => {
        npi && draftAssignmentPromises.push(
          dispatch('profile/assignPractitioner', { npi, type }, { root: true }).then(() => {
            draftAssignmentResults.success.push(npi);
          }).catch(() => {
            draftAssignmentResults.rejected.push(npi);
          })
        )
      })
    })
    await Promise.allSettled(draftAssignmentPromises);
    commit('setPractitionerAssignmentResults', draftAssignmentResults);
  },
  toggleEditMode ({ commit }) {
    commit('setEditMode', !state.isEditMode);
  },
  discardChanges ({ commit }) {
    commit('setCareTeamUpdateArr', []);
    commit('setEditMode', false);
  }
}